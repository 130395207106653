
import {
  defineComponent,
  ref,
  toRefs,
  watch,
  PropType,
  EmitsOptions,
  onMounted,
} from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import { hideModal } from "@/core/helpers/dom";
//import Swal from "sweetalert2/dist/sweetalert2.js";
import * as Yup from "yup";
import * as Mast from "@/models/MasterModel";
import ApiService from "@/core/services/ApiService";
import { useRouter } from "vue-router";
//import { Address } from "@/models/DashboardModel";
import * as Dash from "@/models/DashboardModel";
import Dashboard from "@/models/DashboardModel";
import { useI18n } from "vue-i18n";

export default defineComponent({
  name: "dashboard-edit-form",
  components: {
    ErrorMessage,
    Field,
    Form,
  },
  props: {
    dashboard: {
      required: true,
      type: Number,
    },
    initDashboardEditForm: {
      required: true,
      type: Number,
    },
  },
  emits: ["dashboardUpdated", "cancelDashboardEdit"],

  setup(props, context) {
    const submitButtonRef = ref<null | HTMLButtonElement>(null);
    const modalEditDashboardRef = ref<null | HTMLElement>(null);
    const router = useRouter();
    const { t} = useI18n();
    const newDashboardData = ref<Dashboard>({
      id: 0,
      displayName: "",
    } as Dashboard);
    //newSerieData.value = getEmptySerie();
    const validationSchema = Yup.object().shape({
      displayName: Yup.string().required().label("Name"),
      // displayOrder: Yup.number().required().label("Displayorder"),
    });

    const { initDashboardEditForm } = toRefs(props);

    const submit = () => {
      console.log("Submit");
      if (!submitButtonRef.value) {
        return;
      }
      //Disable button
      submitButtonRef.value.disabled = true;
      // Activate indicator
      submitButtonRef.value.setAttribute("data-kt-indicator", "on");

      ApiService.post(
        "/dashboard/dashboardupdate",
        newDashboardData.value as any
      )
        .then(({ data }) => {
          console.log("submit dashboard update response data:"); //todo geert
          console.log({ ...data }); //todo geert
          // router.push({ name: "editschart",params: {slaveId:data.slaveId} });

          if (submitButtonRef.value) {
            submitButtonRef.value.disabled = false;
            submitButtonRef.value?.removeAttribute("data-kt-indicator");
          }
          context.emit("dashboardUpdated");
          hideModal(modalEditDashboardRef.value);
        })
        .catch(({ response }) => {
          console.log("Fout dashboardUpdate");
        });
    };

    const getDashboardById = (id) => {
      if (!(id > 0)) {
        newDashboardData.value = { id: 0, displayName: "" } as Dashboard;

        console.log("newDashboardData");
        console.log(newDashboardData.value);

        return;
      }

      ApiService.get2("/dashboard/dashboardget?id=" + id)
        .then(({ data }) => {
          console.log("getdashboard response data:"); //todo geert
          console.log({ ...data }); //todo geert
          newDashboardData.value = data as Dashboard;
        })
        .catch(({ response }) => {
          console.log("Fout getSerieById");
        });
    };

    //watch(serieId, getSerieById)
    watch(initDashboardEditForm, () => {
      console.log("DashboardId: " + props.dashboard);
      getDashboardById(props.dashboard);
    });

    return {
      newDashboardData,
      validationSchema,
      submit,
      submitButtonRef,
      modalEditDashboardRef,
      t
    };
  },
});
