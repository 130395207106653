
import {
  defineComponent,
  onMounted,
  onUnmounted,
  PropType,
  ref,
  computed,
} from "vue";
import ApiService from "@/core/services/ApiService";
//import  Master from "@/models/MasterModel"
import * as Mast from "@/models/MasterModel";
import { useI18n } from "vue-i18n";
import { string } from "yup";

export default defineComponent({
  name: "command-btn",
  props: {
    command: {
      required: true,
      type: Object as PropType<Mast.Command>,
    },
  },
  setup(props) {
    const { t } = useI18n();
    const commandButtonRef = ref<null | HTMLButtonElement>(null);
    const commandButtonRef2 = ref<null | HTMLButtonElement>(null);
    const readValue = ref(0);
    const writeValue = ref(0);
    const readWriteState = ref("Idle");
    let timerRef = 0;

    const repsonseTimer = ref();


    const clickCommand = (inputValue  ) => {
      console.log("commandclick");
      if (props.command.commandType.int == 1 || props.command.commandType.int == 3 ) 
      {
        if (props.command.commandType.int == 1) 
        {
          if (readValue.value > 0)
            //toggle boolean
            sendCommand("W", "0");
          else
            sendCommand("W", "1");
        } 
        else 
        {
          sendCommand("W", writeValue.value.toString());
        }

      } 
      else if (props.command.commandType.int == 5)
      {
        writeValue.value = inputValue;
        sendCommand("W",inputValue);
      }
      else
        sendCommand("R", "");
    };

    function sendCommand(ReadOrWrite: string, valueToWrite: string) {
      if (!commandButtonRef.value) {
        console.log("leeg");
        return;
      }
      commandButtonRef.value.disabled = true;
      commandButtonRef.value.setAttribute("data-kt-indicator", "on");

      if (commandButtonRef2.value) {
        commandButtonRef2.value.disabled = true;
        commandButtonRef2.value.setAttribute("data-kt-indicator", "on");
      }


      var message: string;

      if (ReadOrWrite == "W") {
        message = //Todo Geert TransactionId is nu command Id, evt nog een random oid toeveoegen.
          '{ "AddressId" :  ' +
          props.command.writeToAddressId +
          ',"Value":' +
          valueToWrite +
          ',"TransactionId":' +
          props.command.id +
          " }";

        readWriteState.value = "waitForWrite";
      } else {
        message = //Todo Geert TransactionId is nu command Id, evt nog een random oid toeveoegen.
          '{ "AddressId" :  ' +
          props.command.readFromAddressId +
          ',"TransactionId":' +
          props.command.id +
          " }";
        readWriteState.value = "waitForRead";
      }

      console.log("Sending: " + ReadOrWrite +'   ' +  message );

      repsonseTimer.value = setTimeout(commandReceiveTimeout, 3000);

      ApiService.srConnection
        .invoke("SendAddressCommand", message)
        .then(function (data) {
          console.log("SendAddresCommanddata");
          console.log(data);
          let jsonData = JSON.parse(data);
        })
        .catch(function (err) {
          return console.error(err.toString());
        });
    }

    const commandReceiveTimeout  = ()=>{
        
        console.log('commandReceiveTimeout');

      if (!commandButtonRef.value) {
        return;
      }


        //commandButtonRef.value.setAttribute("data-kt-indicator", "on");
        commandButtonRef.value.setAttribute("data-kt-indicator", "off");
        commandButtonRef.value.classList.add("btn-danger");

        if(commandButtonRef2.value){
          commandButtonRef2.value.setAttribute("data-kt-indicator", "off");
          commandButtonRef2.value.classList.add("btn-danger");

        }
    };

    onMounted(() => {
      console.log("Button mounted command :");
      console.log(props.command);

      console.log("go listen on:");
      console.log("ReceiveAddressCommand" + props.command.id);
      ApiService.srConnection.on(
        "ReceiveAddressCommand" + props.command.id,
        (data) => {

          clearTimeout(repsonseTimer.value);
          commandButtonRef.value?.classList.remove("btn-danger");
          commandButtonRef2.value?.classList.remove("btn-danger");


          // todo Geert dit kan een aparte functie zijn 
          let jsonData = JSON.parse(data);
          console.log("Message received:ReceiveAddressCommand" + props.command.id + '    ' + readWriteState.value + ' value :' + jsonData.Value);
          //console.log(jsonData.Value);
          if (readWriteState.value == "waitForRead") {
            readValue.value = jsonData.Value;
            readWriteState.value = "Idle";
          }
          if (readWriteState.value == "waitForWrite") {
            //readValue.value = jsonData.Value;
            sendCommand("R", "");
          }

          if (!commandButtonRef.value) {
            return;
          }
          commandButtonRef.value.disabled = false;  
          commandButtonRef.value?.removeAttribute("data-kt-indicator");

        if(commandButtonRef2.value)
        {
          commandButtonRef2.value.disabled = false;  
          commandButtonRef2.value?.removeAttribute("data-kt-indicator");
        }


        }
      );
      sendCommand("R", ""); //bij mounten altijd meteen een lees actie om de juiste waarde te zetten en vervolgens elke X sec.
      if (timerRef == 0) {
        //timerRef = setInterval(() => sendCommand("R",""), 10000);
      }
    });

    onUnmounted(() => {
      console.log("unmount");
      if (timerRef > 0) {
        clearInterval(timerRef);
      }
    });

    return {
      commandButtonRef,
      commandButtonRef2,
      clickCommand,
      readValue,
      writeValue,
      t,
    };
  },
});
