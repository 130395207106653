
import {
  defineComponent,
  PropType,
  ref,
  toRefs,
  watch,
  onMounted,
  onBeforeUnmount,
  computed,
} from "vue";
//import { getCSSVariableValue } from "@/assets/ts/_utils";
import { Chart } from "highcharts-vue";
import Highcharts from "highcharts";
import hcMore from "highcharts/highcharts-more";
import HighchartsSolidGauge from "highcharts/modules/solid-gauge";
import Exporting from "highcharts/modules/exporting";
import ExporData from "highcharts/modules/export-data";
import { ErrorMessage, Field, Form } from "vee-validate";
import { useI18n } from "vue-i18n";
import * as Dash from "@/models/DashboardModel";
//import { string } from "yup/lib/locale";
import ApiService from "@/core/services/ApiService";
import { useRouter } from "vue-router";
//import { AnyObject } from "yup/lib/object";
import { useStore } from "vuex";
import Swal from "sweetalert2/dist/sweetalert2.js";
import * as Yup from "yup";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import { AgGridVue } from "ag-grid-vue3";
import commandsBucket from  "@/components/CommandsBucket.vue"
import alarmBtn from "@/components/alarmsBtn.vue"
//import { createTypeAnnotationBasedOnTypeof } from "@babel/types";

hcMore(Highcharts);
HighchartsSolidGauge(Highcharts);
Exporting(Highcharts);
ExporData(Highcharts);

export default defineComponent({
  name: "highch-1",
  props: {
    chartConfig: {
      required: true,
      type: Object as PropType<Dash.Chart>,
    },
    chartFilter: {
      required: true,
      type: Object as PropType<Dash.ChartFilter>,
    },
    refreshCharts: {
      required: true,
      type: Number,
    },
  },
  emits: ["chartDeleted"],
  components: {
    highcharts: Chart,
    AgGridVue,
    commandsBucket,
    ErrorMessage,
    Field,
    Form,
  },

  setup(props, context) {
    const store = useStore();
    let dashboardEditMode = store.getters.dashboardEditMode;
    let showFilter = ref(false);
    const router = useRouter();
    const width = ref(3);
    const { t } = useI18n();

    const chartOptions = ref<Dash.Chart>({} as Dash.Chart);
    let timerRef = 0;
    const showSpinner = ref(false);

    const tmpUnit = ref("Day");
    const { refreshCharts } = toRefs(props);

    const commandBuck = ref<Dash.simpleDashboard>({id: 1,  displayName: "een displayname",  charts: []})

    const validationSchema = Yup.object().shape({
      unit: Yup.string().required().label("Unit"),
      unitDateRange: Yup.string().label("unitDateRange"),
      //startPeriod: Yup.date().label("Period start"),
      //endPeriod: Yup.date().label("Period end"),
      lastPoints: Yup.number()
        .max(1000)
        .nullable(true)
        .transform((v) => (v === "" || Number.isNaN(v) ? null : v)),
    });

    const rowData2 = ref<Array<any>>([]);
    var rowData = ref([{} as any]);
    var columnDefs = ref([{} as any]);

    var cardType = ref(1);

    function getGaugeData() {
      if (!chartOptions.value.series[0]) {
        console.log("No serie");
        return;
      }
      var message =
        '{ "SerieIds" : [ ' + chartOptions.value.series[0].id + " ]}";
      //console.log('Sending:'+ message);
      ApiService.srConnection
        .invoke("LastValues", message)
        .then(function (data) {
          //console.log(data);
          let jsonData = JSON.parse(data);
          chartOptions.value.series[0].data[0] = jsonData.Items[0].Value; })
        .catch(function (err) {
          return console.error(err.toString());
        });
    }

    const getChartConfig = () => {
      var requestData = props.chartFilter as any;
      requestData.chartId = props.chartConfig.id;

      showSpinner.value = true;
      ApiService.post("/dashboard/chartget", requestData)
        .then(({ data }) => {
          width.value = data.internalChart.width;
          cardType.value = data.internalChart.chartTypeId;

          if (cardType.value == 1) {
            data.legend = {};
          }

          if (cardType.value == 2) {
            data.tooltip = {};
          }

          if (cardType.value == 3) {
            data.title = { text: data.title };
            if (timerRef == 0) {
              timerRef = setInterval(getGaugeData, 3000);
            }
          }

         

          if (cardType.value == 4) {
           
           columnDefs.value = data.columnHeaders;
           //rowData.value.length = data.rows.length;
           for (var i = 0; i < data.rows.length; i++) {
              let item = data.rows[i];

              rowData.value[i] = {};
              rowData.value[i]["time"] = item.label;

              for (var t3 = 0; t3 < item.values.length; t3++) {
                rowData.value[i]["var" + t3] = item.values[t3];
              }
            }
            // console.log("rowData.value")
            // console.log(rowData.value)
            
          }
          if (cardType.value == 5) {
                console.log("command")
                //console.log(chartOptions.value)
          }
          
          chartOptions.value = data;
          showSpinner.value = false;
        })
        .catch(({ response }) => {
          console.log("Fout getChartConfig");
          showSpinner.value = false;
        });
    };

    const editChart = () => {
      router.push({
        name: "editchart",
        params: { chartId: props.chartConfig.id },
      });
    };

    const clickDeleteChart = () => {
      Swal.fire({
        title:
          "Are you sure to delete chart " +
          props.chartConfig.displayName +
          " ?",
        showCancelButton: true,
        icon: "warning",
        buttonsStyling: true,
        confirmButtonText: "Ok",
        cancelButtonText: "Cancel",
      }).then((result) => {
        if (result.isConfirmed) {
          deleteChart();
        }
      });
    };

    const deleteChart = () => {
      ApiService.post("/dashboard/chartdelete", props.chartConfig as any)
        .then(({ data }) => {
          context.emit("chartDeleted");
        })
        .catch(({ response }) => {
          console.log("Fout ChartDelete");
        });
    };

    const showFilterChart = () => {
      if (chartOptions.value.internalChart.chartDataRequest)
        if (
          chartOptions.value.internalChart.chartDataRequest.startPeriod ==
            null ||
          chartOptions.value.internalChart.chartDataRequest.endPeriod == null
        ) {
          chartOptions.value.internalChart.chartDataRequest.startPeriod =
            new Date();
          chartOptions.value.internalChart.chartDataRequest.endPeriod =
            new Date();
        }
      showFilter.value = !showFilter.value;
    };

    const cancelFilterChart = () => {
      showFilter.value = false;
    };

    const refreshData = () => {
      console.log("Refresh");
      getChartConfig();
    };

    watch(refreshCharts, () => {
      console.log(
        "refresh chart:" +
          props.chartConfig.id +
          " lastpoints:" +
          props.chartFilter.lastPoints
      );
      refreshData();
    });

    //filter update
    const updateFilterChart = () => {
      showFilter.value = false;
      ApiService.post(
        "/dashboard/chartdatarequestupdate",
        chartOptions.value.internalChart.chartDataRequest as any
      )
        .then(({ data }) => {
          console.log("chartdatarequestupdate response data:"); //todo geert
          console.log(data);
          getChartConfig();
        })
        .catch(({ response }) => {
          console.log("Fout chartdatarequestupdate");
        });
    };

    const showOptions = () => {
      alert(chartOptions.value);
    };

    const selectableUnits = computed(() => {
      var maxPoints = chartOptions.value.internalChart.maxPoints;
      var units: Array<string> = [];
      if (chartOptions.value.internalChart.chartDataRequest) {
        var startPeriod = new Date(
          chartOptions.value.internalChart.chartDataRequest.startPeriod.toString()
        ).getTime();
        var endPeriod = new Date(
          chartOptions.value.internalChart.chartDataRequest.endPeriod.toString()
        ).getTime();
        if (startPeriod > 0 && endPeriod > 0) {
          let timeSpan = endPeriod - startPeriod;
          if (timeSpan / (1000 * 60) < maxPoints) units.push("Minute");
          if (timeSpan / (1000 * 60 * 60) < maxPoints) units.push("Hour");
          if (timeSpan / (1000 * 60 * 60 * 24) < maxPoints) units.push("Day");
          if (timeSpan / (1000 * 60 * 60 * 24 * 30) < maxPoints)
            units.push("Month");
          if (timeSpan / (1000 * 60 * 60 * 24 * 365) < maxPoints)
            units.push("Year");
        }
      }
      //console.log(units);
      return units;
    });

    const periodChanged = () => {
      if (chartOptions.value.internalChart.chartDataRequest)
        chartOptions.value.internalChart.chartDataRequest.unit = "";

      setLastPointsSelected(0);
    };

    const setLastPointsSelected = (value) => {
      if (chartOptions.value.internalChart.chartDataRequest)
        chartOptions.value.internalChart.chartDataRequest.lastPointsSelected =
          value;
    };

    onMounted(() => {
      getChartConfig();
    });

    onBeforeUnmount(() => {
      if (timerRef > 0) {
        clearInterval(timerRef);
      }
    });

    return {
      showOptions,
      validationSchema,
      updateFilterChart,
      editChart,
      chartOptions,
      refreshData,
      dashboardEditMode,
      clickDeleteChart,
      showFilterChart,
      cancelFilterChart,
      showSpinner,
      width,
      showFilter,
      t,
      selectableUnits,
      periodChanged,
      setLastPointsSelected,
      rowData,
      columnDefs,
      cardType,
      commandBuck,
      
    };
  },
  methods: {},
});
