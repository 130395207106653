
import {
  defineComponent,
  ref,
  toRefs,
  watch,
  PropType,
  EmitsOptions,
  onMounted,
  computed,
} from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import { hideModal } from "@/core/helpers/dom";
import Swal from "sweetalert2/dist/sweetalert2.js";
import * as Yup from "yup";
import * as Mast from "@/models/MasterModel";
import ApiService from "@/core/services/ApiService";
import { useRouter } from "vue-router";
//import { Address } from "@/models/DashboardModel";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";

interface sortItem {
  id: number;
  name: string;
}

export default defineComponent({
  name: "sort-order-form-modal",
  components: {
    Form,
  },
  props: {
    // initAddressEditForm: {
    //   required: true,
    //   type: Number,
    // },
    toSortId: {
      required: true,
      type: Number,
    },
    toSortType: {
      required: true,
      type: String,
    },
  },
  emits: ["sortOrderUpdated","sortOrderEditCancel"],

  setup(props, context) {
    const submitButtonRef = ref<null | HTMLButtonElement>(null);
    const modalEditAddressRef = ref<null | HTMLElement>(null);

    const store = useStore();
    const { t} = useI18n();
    
    const listToSort = ref<sortItem[]>([
      { id: 1, name: "Grafiek een" },
      { id: 2, name: "Grafiek twee" },
      { id: 3, name: "Grafiek drie" },
      { id: 4, name: "Grafiek Vier" },
      { id: 5, name: "Grafiek Vijf" },
      { id: 6, name: "Grafiek Zes" },
    ]);

    const clickDown = (index) => {
      if (index > listToSort.value.length - 2) return;
      let tmpItem = listToSort.value[index + 1];
      listToSort.value[index + 1] = listToSort.value[index];
      listToSort.value[index] = tmpItem;
    };

    const clickUp = (index) => {
      if (index < 1) return;
      let tmpItem = listToSort.value[index - 1];
      listToSort.value[index - 1] = listToSort.value[index];
      listToSort.value[index] = tmpItem;
    };

    const clickCancel = () => {
      context.emit("sortOrderEditCancel");
    };

    const submit = () => {
      console.log("Submit");
      if (!submitButtonRef.value) {
        return;
      }
      //Disable button
      submitButtonRef.value.disabled = true;
      // Activate indicator
      submitButtonRef.value.setAttribute("data-kt-indicator", "on");
      context.emit("sortOrderUpdated");
      ApiService.post("/dashboard/" + props.toSortType + "orderupdate", {
        parentId: props.toSortId,
        items: listToSort.value,
      } as any)
        .then(({ data }) => {
          console.log("submit listToSort response data:"); //todo geert
          console.log(data ); //todo geert

          if (submitButtonRef.value) {
            submitButtonRef.value.disabled = false;
            submitButtonRef.value?.removeAttribute("data-kt-indicator");
          }
          context.emit("sortOrderUpdated");
        })
        .catch(({ response }) => {
          //todo algemene foutfunctie maken voor in de catches
          console.log("Fout listToSort");
          console.log(response);
          Swal.fire({
            text: ApiService.getModelStateError(response.data.errors), //todo algemene error message
            icon: "error",
            buttonsStyling: true,
            confirmButtonText: "Ok",
          });
          if (submitButtonRef.value) {
            submitButtonRef.value.disabled = false;
            submitButtonRef.value?.removeAttribute("data-kt-indicator");
          }
        });
    };

    const getToSortBy = (id) => {
      let urlPart = (props.toSortType == 'command') ? "chartId": "dashBoardId";
      ApiService.get2("/dashboard/"+ props.toSortType +"orderget/?"+urlPart+"=" + id)
        .then(({ data }) => {
          console.log("tosort response data:"); //todo geert
          console.log({ ...data }); //todo geert
          listToSort.value = data.items;
        })
        .catch(({ response }) => {
          console.log("Fout toSort");
        });
    };

    

    onMounted(() => {
      getToSortBy(props.toSortId);

    });

    return {
      submit,
      submitButtonRef,
      modalEditAddressRef,
      listToSort,
      clickDown,
      clickUp,
      clickCancel,
      t,
    };
  },
});
