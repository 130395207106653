
import { defineComponent, PropType, ref, onMounted, toRefs, watch } from "vue";
import HighCh1 from "@/components/widgets/charts/HighCh1.vue";
import { Store, useStore } from "vuex";
import { useRouter } from "vue-router";
import { ErrorMessage, Field, Form } from "vee-validate";
import * as Yup from "yup";
import ApiService from "@/core/services/ApiService";
import Dashboard from "@/models/DashboardModel";
import { ChartFilter } from "@/models/DashboardModel";
//import ChartFilterDropdown from "@/components/ChartFilterDropdown.vue"
import KTToolbar from "@/layout/toolbar/Toolbar.vue";
import ChartFilterForm from "@/components/ChartFilterForm.vue";
import SortOrderModal from "@/components/SortOrderModal.vue";
import moment from "moment";
import { useI18n } from "vue-i18n";

interface simpleDashboard {
  id: number;
  displayName: string;
  charts: [];
}

export default defineComponent({
  name: "dashboard-tab-component",
  components: {
    HighCh1,
    SortOrderModal,
  },
  props: {
    tab: {
      required: true,
      type: Object as PropType<simpleDashboard>, //(ref <{} as Dash.Chart>)   // ref<Dashboard>({}as Dashboard);
    },
  },
  setup(props) {
    const store = useStore();
    const router = useRouter();
    const { t } = useI18n();
   

    const { tab } = toRefs(props);
    let chartFilter7 = store.getters.chartFilter;
    let dashboardEditMode = store.getters.dashboardEditMode;

    const validationSchema = Yup.object().shape({
      unit: Yup.string().required().label("Unit"),
      startPeriod: Yup.date().required().label("Period start"),
      endPeriod: Yup.date().required().label("Period end"),
      lastPoints: Yup.number().required().label("lastPoints"),
    });

    const dashboard = ref<Dashboard>({} as Dashboard);
    const initialized = ref<boolean>(true);
    const refreshCharts = ref<number>(0);
    const filterModalId = ref<string>("modal_chart_filter" + props.tab.id);

    const showSort = ref(false);
    const clickShowSortModal = () => {
      showSort.value = !showSort.value;
    };

    const cancelFilterChart = () => {
      showSort.value = false;
    };

    const getDashboard = () => {
      //initialized.value = false;
      ApiService.get2("/dashboard/dashboardget?id=" + props.tab.id)
        .then(({ data }) => {
          console.log("dashboard response data:"); //todo geert
           console.log(data); //todo geert
          dashboard.value = data;
          //initialized.value = true;
        })
        .catch(({ response }) => {
          console.log("Fout getDashBoard");
        });
    };

    const newChart = () => {
      ApiService.post("/dashboard/chartnew", {
        dashboardId: props.tab.id,
        chartTypeId: 1,
        title: "New",
      } as any)
        .then(({ data }) => {
          // console.log("chartnew response data:" + { ...data }); //todo geert
          // console.log(data);
          router.push({
            name: "editchart",
            params: { chartId: data.internalChart.id },
          });
        })
        .catch(({ response }) => {
          console.log("Fout chartnew");
        });
    };

    const submitFilter = () => {
      console.log("submitfilter");
      console.log({ ...chartFilter7.value });
      //getDashboard();
      refreshCharts.value = Math.random(); //trucje om trigger van de watch te bewerkstelligen.
    };

    const chartFilterUpdated = () => {
      console.log("chartFilterUpdated");
      submitFilter();
    };

    const sortOrderUpdated = () => {
      showSort.value = false;
      getDashboard();
    };

    const sortOrderEditCancel = () => {
      showSort.value = false;
     };

    watch(tab, () => {
      console.log("getDashboard");
      getDashboard();
    });

    onMounted(() => {
      console.log("id is:"+ props.tab.id)
      chartFilter7.currentDashBoardId = props.tab.id;
      getDashboard();
    });

    return {
      dashboard,
      initialized,
      newChart,
      getDashboard,
      submitFilter,
      validationSchema,
      refreshCharts,
      chartFilterUpdated,
      chartFilter7,
      moment,
      dashboardEditMode,
      filterModalId,
      sortOrderUpdated,
      clickShowSortModal,
      sortOrderEditCancel,
      showSort,
      t,
    };
  },
  methods: {},
});
