
import { defineComponent, PropType, ref, onMounted, toRefs, watch } from "vue";
import HighCh1 from "@/components/widgets/charts/HighCh1.vue";
import { Store, useStore } from "vuex";
import { useRouter } from "vue-router";
import { ErrorMessage, Field, Form } from "vee-validate";
import * as Yup from "yup";
import ApiService from "@/core/services/ApiService";
import Dashboard from "@/models/DashboardModel";
import { ChartFilter } from "@/models/DashboardModel";
//import ChartFilterDropdown from "@/components/ChartFilterDropdown.vue"
import KTToolbar from "@/layout/toolbar/Toolbar.vue";
import ChartFilterForm from "@/components/ChartFilterForm.vue";
import SortOrderModal from "@/components/SortOrderModal.vue";
import moment from "moment";
import commandBtn from "@/components/commandButton.vue";
import { useI18n } from "vue-i18n";
import * as Mast from "@/models/MasterModel";
import * as Dash from "@/models/DashboardModel";

interface simpleBucket {
  id: number;
  displayName: string;
}

export default defineComponent({
  name: "commands-bucket",
  components: {
    SortOrderModal,
    commandBtn,
  },
  props: {
    // commands:{
    //   required: true,
    //   type: Object as PropType<Mast.Command[]>,
    // },
    commandBuck: {
      required: true,
      type: Object as PropType<simpleBucket>, //(ref <{} as Dash.Chart>)   // ref<Dashboard>({}as Dashboard);
    },
  },
  setup(props) {
    const store = useStore();
    const router = useRouter();
    const { t } = useI18n();

    const commands = ref<Mast.Command[]>([]);
    //const { tab } = toRefs(props);
    let chartFilter7 = store.getters.chartFilter;
    let dashboardEditMode = store.getters.dashboardEditMode;

    const validationSchema = Yup.object().shape({
      unit: Yup.string().required().label("Unit"),
      startPeriod: Yup.date().required().label("Period start"),
      endPeriod: Yup.date().required().label("Period end"),
      lastPoints: Yup.number().required().label("lastPoints"),
    });
    
    const dashboard = ref<Dashboard>({} as Dashboard);
    const refreshCharts = ref<number>(0);
    const filterModalId = ref<string>(
      "modal_chart_filter" + props.commandBuck.id
    );

    const showSort = ref(false);
    const clickShowSortModal = () => {
      showSort.value = !showSort.value;
    };

    const cancelFilterChart = () => {
      showSort.value = false;
    };

    const getCommands = () => {
      const postData = {
        searchString: "",
        parentIds: [props.commandBuck.id],
        pageSize: 10000,
        pageNumber: "",
      } as any;
      ApiService.post("/dashboard/commands", postData)
        .then(({ data }) => {
          console.log("getCommands response data:"); //todo geert
          console.log({ ...data }); //todo geert
          commands.value = data.results;
        })
        .catch(({ response }) => {
          console.log("Fout getCommands");
        });
    };

    const newChart = () => {
      // ApiService.post("/dashboard/chartnew", {
      //   dashboardId: props.tab.id,
      //   chartTypeId: 1,
      //   title: "New",
      // } as any)
      //   .then(({ data }) => {
      //     // console.log("chartnew response data:" + { ...data }); //todo geert
      //     // console.log(data);
      //     router.push({
      //       name: "editchart",
      //       params: { chartId: data.internalChart.id },
      //     });
      //   })
      //   .catch(({ response }) => {
      //     console.log("Fout chartnew");
      //   });
    };

    const submitFilter = () => {
      console.log("submitfilter");
      console.log({ ...chartFilter7.value });
      //getDashboard();
      refreshCharts.value = Math.random(); //trucje om trigger van de watch te bewerkstelligen.
    };

    const chartFilterUpdated = () => {
      console.log("chartFilterUpdated");
      submitFilter();
    };

    const sortOrderUpdated = () => {
      showSort.value = false;
      getCommands();
    };

    const sortOrderEditCancel = () => {
      showSort.value = false;
    };

    // watch(tab, () => {
    //   console.log("getDashboard");
    //   getCommands();
    // });

    onMounted(() => {
      console.log("CommandBuck is:");
      console.log(props.commandBuck);

      //chartFilter7.currentDashBoardId = props.tab.id;
      getCommands();
    });

    return {
      
      dashboard,
      newChart,
      getCommands,
      submitFilter,
      validationSchema,
      refreshCharts,
      chartFilterUpdated,
      chartFilter7,
      moment,
      dashboardEditMode,
      filterModalId,
      sortOrderUpdated,
      clickShowSortModal,
      sortOrderEditCancel,
      showSort,
      commands,
      t,
    };
  },
  methods: {},
});
