
import { computed, defineComponent, onMounted, ref, toRefs } from "vue";
import DashboardTabContent from "@/components/DashboardTabContent.vue";
import DashboardEditForm from "@/components/dashboardEditForm.vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import Dropdown3 from "@/components/dropdown/Dropdown3.vue";
import ApiService from "@/core/services/ApiService";
import Dashboard from "@/models/DashboardModel";
import SortOrderModal from "@/components/SortOrderModal.vue";
import { useStore } from "vuex";
import { ErrorMessage, Field, Form } from "vee-validate";
import * as Yup from "yup";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { useI18n } from "vue-i18n";

interface simpleDashboard {
  id: number;
  displayName: string;
  charts: [];
}

export default defineComponent({
  name: "customer-details",
  components: {
    DashboardTabContent,
    DashboardEditForm,
    Field,
    SortOrderModal,
  },

  setup() {
    const store = useStore();
    const { t} = useI18n();
    
    let dashboardEditMode = store.getters.dashboardEditMode;

    const currentTabId = ref<number>(0);
    const dashboards = ref<simpleDashboard[]>({} as simpleDashboard[]);

    const initDashboardEditForm = ref<number>(0);
    var dashboardToEdit = ref<number>(0);
    const initialized3 = ref<boolean>(true);

    const showSort = ref(false);

    const clickShowSortModal = () => {
      showSort.value = !showSort.value;
    };

    const getDashboards = () => {
      ApiService.post("/dashboard/dashboards", {})
        .then(({ data }) => {
          console.log("dashboards response data:"); //todo geert
          dashboards.value = data.results;
          let curTab = window.localStorage.getItem("curTab");
          if (curTab) setCurrentTabId(curTab);
        })
        .catch(({ response }) => {
          console.log("Fout getDashBoards");
        });
    };

    const clickDeleteDashboard = (data: Dashboard) => {
      Swal.fire({
        title: "Are you sure to delete Dashboard " + data.displayName + " ?",
        showCancelButton: true,
        icon: "warning",
        buttonsStyling: true,
        confirmButtonText: "Ok",
        cancelButtonText: "Cancel",
      }).then((result) => {
        if (result.isConfirmed) {
          deleteDashboard(data);
        }
      });
    };

    const deleteDashboard = (data) => {
      console.log("deleteDashboard:");
      console.log(data);
      //if(currentTabId.value == data.id) currentTabId.value = 0; Misschien nogig?
      ApiService.post("/dashboard/dashboarddelete", { id: data.id } as any)
        .then(({ data }) => {
          console.log("dashboarddelete response data:" + { ...data }); //todo geert
          getDashboards();
        })
        .catch(({ response }) => {
          console.log("Fout dashboardupdate");
        });
    };

    const clickNewDashboard = () => {
      console.log("goNewDashboard");

      dashboardToEdit.value = 0;
      initDashboardEditForm.value = Math.random(); //trucje om trigger van de watch te bewerkstelligen.
    };

    const clickDashboardEditMode = () => {
      console.log("EditDashboardMode");
      dashboardEditMode = 1;
    };

    const clickEditDashboard = (data) => {
      console.log("goEditDashboard");
      console.log(data);
      //router.push({ name: "editserie",params: {chartId:props.chartId,serieId:data.id}});
      dashboardToEdit.value = data.id;
      initDashboardEditForm.value = Math.random(); //trucje om trigger van de watch te bewerkstelligen.
    };

    const setCurrentTabId = (index) => {
      if (index > dashboards.value.length - 1) index = 0;
      currentTabId.value = index;
      window.localStorage.setItem("curTab", index);
      console.log("currentTab is:" + currentTabId.value);
    };

    const cancelDashboardEdit = () => {
      console.log("cancelEditDashboard");
      //router.push({ name: "editserie",params: {chartId:props.chartId,serieId:data.id}});
      //showSerieEditForm.value = false;
      dashboardToEdit.value = 0;
    };

    const dashboardUpdated = () => {
      console.log("DashboardUpdated");
      //router.push({ name: "editserie",params: {chartId:props.chartId,serieId:data.id}});
      //showSerieEditForm.value = false;
      dashboardToEdit.value = 0;
      getDashboards();
    };

    const sortOrderUpdated = () => {
      showSort.value = false;
      getDashboards();
    };

    const sortOrderEditCancel = () => {
      showSort.value = false;
     };
    

    onMounted(() => {
      //MenuComponent.reinitialization();
      getDashboards();
      setCurrentPageTitle("Dashboards");
    });

    return {
      dashboards,
      initialized3,
      clickDashboardEditMode,
      dashboardEditMode,
      dashboardUpdated,
      cancelDashboardEdit,
      clickEditDashboard,
      clickNewDashboard,
      dashboardToEdit,
      initDashboardEditForm,
      clickDeleteDashboard,
      currentTabId,
      setCurrentTabId,
      sortOrderUpdated,
      clickShowSortModal,
      sortOrderEditCancel,
      showSort,
      t,
    };
  },
  methods: {},
});
